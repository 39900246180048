export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL'
export const USER_LOGOUT_RESET = 'USER_LOGOUT_RESET'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET'

export const GET_USER_ACTIVATION_LINK_REQUEST = 'GET_USER_ACTIVATION_LINK_REQUEST'
export const GET_USER_ACTIVATION_LINK_SUCCESS = 'GET_USER_ACTIVATION_LINK_SUCCESS'
export const GET_USER_ACTIVATION_LINK_FAIL = 'GET_USER_ACTIVATION_LINK_FAIL'
export const GET_USER_ACTIVATION_LINK_RESET = 'GET_USER_ACTIVATION_LINK_RESET'

export const USER_ACTIVATION_REQUEST = 'USER_ACTIVATION_REQUEST'
export const USER_ACTIVATION_SUCCESS = 'USER_ACTIVATION_SUCCESS'
export const USER_ACTIVATION_FAIL = 'USER_ACTIVATION_FAIL'
export const USER_ACTIVATION_RESET = 'USER_ACTIVATION_RESET'

export const USER_FORGOT_PASSWORD_LINK_REQUEST = 'USER_FORGOT_PASSWORD_LINK_REQUEST'
export const USER_FORGOT_PASSWORD_LINK_SUCCESS = 'USER_FORGOT_PASSWORD_LINK_SUCCESS'
export const USER_FORGOT_PASSWORD_LINK_FAIL = 'USER_FORGOT_PASSWORD_LINK_FAIL'
export const USER_FORGOT_PASSWORD_LINK_RESET = 'USER_FORGOT_PASSWORD_LINK_RESET'

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST'
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS'
export const USER_RESET_PASSWORD_FAIL = 'USER_RESET_PASSWORD_FAIL'
export const USER_RESET_PASSWORD_RESET = 'USER_RESET_PASSWORD_RESET'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_PAYMENT_CARD_ADD_REQUEST = 'USER_PAYMENT_CARD_ADD_REQUEST'
export const USER_PAYMENT_CARD_ADD_SUCCESS = 'USER_PAYMENT_CARD_ADD_SUCCESS'
export const USER_PAYMENT_CARD_ADD_FAIL = 'USER_PAYMENT_CARD_ADD_FAIL'
export const USER_PAYMENT_CARD_ADD_RESET = 'USER_PAYMENT_CARD_ADD_RESET'

export const USER_SHIPPING_ADDRESS_ADD_REQUEST = 'USER_SHIPPING_ADDRESS_ADD_REQUEST'
export const USER_SHIPPING_ADDRESS_ADD_SUCCESS = 'USER_SHIPPING_ADDRESS_ADD_SUCCESS'
export const USER_SHIPPING_ADDRESS_ADD_FAIL = 'USER_SHIPPING_ADDRESS_ADD_FAIL'
export const USER_SHIPPING_ADDRESS_ADD_RESET = 'USER_SHIPPING_ADDRESS_ADD_RESET'

export const USER_PAYMENT_CARD_LIST_REQUEST = 'USER_PAYMENT_CARD_LIST_REQUEST'
export const USER_PAYMENT_CARD_LIST_SUCCESS = 'USER_PAYMENT_CARD_LIST_SUCCESS'
export const USER_PAYMENT_CARD_LIST_FAIL = 'USER_PAYMENT_CARD_LIST_FAIL'
export const USER_PAYMENT_CARD_LIST_RESET = 'USER_PAYMENT_CARD_LIST_RESET'

export const USER_SHIPPING_ADDRESS_LIST_REQUEST = 'USER_SHIPPING_ADDRESS_LIST_REQUEST'
export const USER_SHIPPING_ADDRESS_LIST_SUCCESS = 'USER_SHIPPING_ADDRESS_LIST_SUCCESS'
export const USER_SHIPPING_ADDRESS_LIST_FAIL = 'USER_SHIPPING_ADDRESS_LIST_FAIL'
export const USER_SHIPPING_ADDRESS_LIST_RESET = 'USER_SHIPPING_ADDRESS_LIST_RESET'
