import { BreadcrumbItem, Breadcrumbs, Button, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns';

const ProfileOrderDetailsScreen = () => {
    const {order} = useParams()

    const [orderInfo, setOrderInfo] = useState()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        setOrderInfo(userInfo.orders.find(f => f._id == order))
    }, [order])
    

  return (
    <div>
        <div className='h-fit w-full flex flex-col gap-8'>
            <section className='h-fit w-full'>
                <div className='h-fit w-full max-w-[1100px] mx-auto px-6'>
                <Breadcrumbs className='py-4'>
                    <BreadcrumbItem className='font-medium'>Home</BreadcrumbItem>
                    <BreadcrumbItem className='font-medium'>My Account</BreadcrumbItem>
                    <BreadcrumbItem className='font-medium'>Order #{}</BreadcrumbItem>
                </Breadcrumbs> 
                <div className='h-fit w-full gap-8 items-start'>
                    <div className='w-full h-[400px]'>
                    <Table aria-label="Example table with dynamic content" className='gap-2 flex flex-col'>
                        <TableHeader >
                            <TableColumn className='font-bold opacity-50 text-base' >Item</TableColumn>
                            <TableColumn className='font-bold opacity-50 text-base' >Quantity</TableColumn>
                            <TableColumn className='font-bold opacity-50 text-base' >Price</TableColumn>
                            <TableColumn className='font-bold opacity-50 text-base' >Veriant</TableColumn>
                        </TableHeader>
                        <TableBody>
                        
                            {
                            orderInfo && orderInfo.orderItems ?
                            orderInfo.orderItems.map(i=>(
                                <TableRow  className=''>
                                    <TableCell className='text-xs font-bold'>{i.name}</TableCell>
                                    <TableCell className='text-xs opacity-75'>{i.qty}</TableCell>
                                    
                                    <TableCell className='text-xs opacity-75'>{i.price}</TableCell>
                                    <TableCell className='text-xs opacity-75'>{i.veriant? i.veriant.name :'Not selected'}</TableCell>
           
                                </TableRow>
                            ))
                            
                            : 
                            
                            <TableRow>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
                                <TableCell>Loading...</TableCell>
           
                            </TableRow>

                            }

                        </TableBody>
                    </Table>
                    </div>
                </div>
                </div>
            </section>
        </div>
    </div>
  )
}

export default ProfileOrderDetailsScreen