import React, { useEffect, useState } from 'react'
import Container from '../componets/Container'
import { BreadcrumbItem, Breadcrumbs, Button, Chip, Navbar, Tab, Tabs } from '@nextui-org/react'
import { CiFilter, CiMenuFries } from "react-icons/ci";
import { TfiLayoutGrid2Alt, TfiLayoutGrid3Alt, TfiLayoutGrid4Alt } from "react-icons/tfi";
import {Accordion, AccordionItem} from "@nextui-org/react";
import {Checkbox} from "@nextui-org/react";
import {Slider} from "@nextui-org/slider";
import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem} from "@nextui-org/react";
import { FaRegStar, FaRegStarHalfStroke, FaStar } from 'react-icons/fa6';
import { Link, useParams } from 'react-router-dom';
import { PiShoppingCartSimpleBold } from 'react-icons/pi';
import { MdArrowRightAlt, MdCancel } from 'react-icons/md';
import {Pagination} from "@nextui-org/react";
import { listProducts } from '../actions/productActions';
import { useDispatch, useSelector } from 'react-redux';

const ProductsScreen = () => {
  const [filter, setFilter] = useState(false);
  const [gridCount, setGridCount] = useState(2);
  const [value, setValue] = React.useState([100, 300]);

  const [currentPage, setCurrentPage] = React.useState(1);

  const dispatch = useDispatch();

  const {category} = useParams()

  const productList = useSelector(state => state.productList);
  const { loading, products } = productList;

  useEffect(() => {
    // Dispatch action to load products
    dispatch(listProducts(currentPage, category));
  }, [dispatch, currentPage, category]); 

  return (
    <div className='h-fit w-full relative py-2'>

      <section className='h-fit w-full'>
        <div className='h-fit w-full max-w-[1100px] mx-auto px-6 flex flex-col gap-4'>
            <Breadcrumbs className='py-4'>
              <BreadcrumbItem className='font-medium'>Shop</BreadcrumbItem>
              <BreadcrumbItem className='font-medium capitalize'>{category.replace(/-|and|\s/g, ' ')}</BreadcrumbItem>
            </Breadcrumbs> 
            <div className='h-fit w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6'>
              
              {
                loading?
                '':
                products && products.products && category?
                products.products.filter(f => f.category ? f.category.slug == category : products.products).map(i => (
                  <div key={i._id} className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
                    <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
                      <Link to={`/shop/category/${i.slug}`} className='h-[150px]'>
                        <img src={
                          i.images[0].image 
                        } alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative '/>
                      </Link>
                      <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-10 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
                          <PiShoppingCartSimpleBold className='text-xs'/>
                      }>
                      </Button>
                    </div>
                    <Link to={`/shop/category/${i.slug}`} className='flex flex-col pt-3 gap-2'>
                      

                      <div className='flex flex-col gap-1'>
                        <p className='text-sm font-semibold'>{i.name}</p>
                        <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
                      </div>

            
    
                      <div className='flex flex-col'>
                        <div className='flex flex-wrap items-center'>
                          <p className='text-lg font-semibold'>Rs {i.discount_price}</p> 
                          <s className='text-xs font-medium opacity-50 mt-1'>Rs {i.price}</s>
                        </div>
                        <div className='flex items-center gap-2'>
                        <div className='flex gap-1'>
                          {[...Array(5)].map((_, index) => (
                            <FaStar
                              key={index}
                              className={`text-sm ${index < i.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                            />
                          ))}
                        </div>
                        <p className='text-sm opacity-50'>({i.numReviews})</p>
                      </div>
                      </div>
                    </Link>
                  </div>
                )):
                ''
              }


            </div>

            <div className='flex items-center md:col-span-2 lg:col-span-3 justify-center py-4'>
              <Pagination size='sm' showControls total={products? products.pages:2} page={currentPage} onChange={setCurrentPage} initialPage={1} className='flex w-full md:hidden' />
              <Pagination size='md' showControls total={products? products.pages:2} page={currentPage} onChange={setCurrentPage} initialPage={1} className='w-full hidden md:flex' />
            </div>
        </div>
      </section>

    </div>
  )
}

export default ProductsScreen


// <div className='flex flex-col md:grid md:grid-cols-3 lg:grid-cols-4 gap-4'>
//               <div className='flex flex-col items-center'>
//                 <div className='w-full flex items-center justify-between py-4'>
//                   <p className='text-xl font-bold'>Filtered</p>

//                   <div className='flex flex-row gap-2'>
//                     <div className='col-span-3'>
//                       <Button onClick={() => setFilter(!filter) } isIconOnly endContent={<CiFilter className='text-xl'/>} variant='flat' className='w-full text-sm font-medium opacity-50 rounded-[8px]' size='md'></Button>
//                     </div>

//                     <Dropdown>
//                       <DropdownTrigger>
//                         <div>
//                           <Button isIconOnly endContent={<CiMenuFries className='text-xl'/>} variant='flat' radius='none' className='w-full text-sm font-medium opacity-50 rounded-[8px]' size='md'><p className=''></p></Button>
//                         </div>
//                       </DropdownTrigger>
//                       <DropdownMenu aria-label="Static Actions">
//                         <DropdownItem key="new">New file</DropdownItem>
//                         <DropdownItem key="copy">Copy link</DropdownItem>
//                         <DropdownItem key="edit">Edit file</DropdownItem>
//                         <DropdownItem key="delete" className="text-danger" color="danger">
//                           Delete file
//                         </DropdownItem>
//                       </DropdownMenu>
//                     </Dropdown> 

//                   </div>
//                 </div>
//                 <div className={
//                   filter?
//                   'h-fit overflow-hidden w-full bg-gray-50 duration-300 px-2 rounded-[8px]':
//                   'h-[0px] md:h-fit overflow-hidden w-full bg-gray-50 duration-300 px-2 rounded-[8px]'
//                 }>
//                   <Accordion variant='light' defaultExpandedKeys={["1","2","3","4"]}>
//                     <AccordionItem key={'1'} title={
//                       <div className='flex items-center justify-between'>
//                         <p className='text-xs md:text-sm font-semibold'>Category</p>
//                       </div>
//                     } >
//                       <div className='flex flex-wrap gap-2'>
//                         <Chip size='sm' variant='flat' className='px-1 py-4 text-opacity-50' >Apple</Chip>
//                         <Chip size='sm' variant='flat' className='px-1 py-4 text-opacity-50' >Samsung</Chip>
//                         <Chip size='sm' variant='flat' className='px-1 py-4 text-opacity-50' >Huwawi</Chip>
//                         <Chip size='sm' variant='flat' className='px-1 py-4 text-opacity-50' >Honer</Chip>
//                       </div>
//                     </AccordionItem>
//                     <AccordionItem key={'2'} title={
//                       <div className='flex items-center justify-between'>
//                         <p className='text-xs md:text-sm font-semibold'>Brand</p>
//                       </div>
//                     } >
//                       <div className='flex flex-wrap gap-2'>
//                         <Chip size='sm' variant='flat' className='px-1 py-4 text-opacity-50' >Huwawi</Chip>
//                         <Chip size='sm' variant='flat' className='px-1 py-4 text-opacity-50' >Honer</Chip>
//                         <Chip size='sm' variant='flat' className='px-1 py-4 bg-[#54C1C4]' endContent={
//                           <MdCancel className='text-base' />
//                         }>Apple</Chip>
//                         <Chip size='sm' variant='flat' className='px-1 py-4 bg-[#54C1C4]' endContent={
//                           <MdCancel className='text-base' />
//                         }>Samsung</Chip>
//                       </div>
//                     </AccordionItem>
//                     <AccordionItem key={'3'} title={
//                       <div className='flex items-center justify-between'>
//                         <p className='text-xs md:text-sm font-semibold'>Colors</p>
//                       </div>
//                     } >
//                       <div className='flex flex-wrap gap-2'>
//                         <Chip size='sm' variant='flat' className='px-1 py-4 text-opacity-50'  startContent={
//                           <div className='h-6 w-6 bg-black rounded-full'>

//                           </div>
//                         }>Huwawi</Chip>
//                         <Chip size='sm' variant='flat' className='px-1 py-4 text-opacity-50'  startContent={
//                           <div className='h-6 w-6 bg-black rounded-full'>

//                           </div>
//                         }>Honer</Chip>
//                         <Chip size='sm' variant='flat' className='px-1 py-4 bg-[#54C1C4]' endContent={
//                           <MdCancel className='text-base' />
//                         } startContent={
//                           <div className='h-6 w-6 bg-black rounded-full'>

//                           </div>
//                         }>Apple</Chip>
//                         <Chip size='sm' variant='flat' className='px-1 py-4 bg-[#54C1C4]' endContent={
//                           <MdCancel className='text-base' />
//                         } startContent={
//                           <div className='h-6 w-6 bg-black rounded-full'>

//                           </div>
//                         }>Samsung</Chip>
//                       </div>
//                     </AccordionItem>
//                     <AccordionItem key={'4'} title={
//                       <div className='flex items-center justify-between'>
//                         <p className='text-xs md:text-sm font-semibold'>Price Range</p>
//                       </div>
//                     } >
//                       <div className='max-w-full overflow-hidden flex flex-wrap gap-2'>
//                         <Slider 
//                           size='sm'
//                           label="Price"
//                           formatOptions={{style: "currency", currency: "USD"}}
//                           step={10}
//                           maxValue={1000}
//                           minValue={0}
//                           value={value} 
//                           onChange={setValue}
//                           className="max-w-full"
//                         />
//                       </div>
//                     </AccordionItem>
//                   </Accordion>
//                 </div>
//               </div>
//               <div className='grid grid-cols-2 gap-2 md:col-span-2 lg:col-span-3 gap-y-4 md:grid-cols-2 lg:grid-cols-3 md:py-4'>
//                 <div className='grid grid-cols-2 gap-2 md:col-span-2 lg:col-span-3 gap-y-4 md:grid-cols-2 lg:grid-cols-3'>
//                     <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
//                       <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
//                         <Link className='h-[150px] relative' to={'/shop/cat/product'}>
//                           <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative z-0'/>
//                         </Link>
//                         {/* <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-25 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
//                             <PiShoppingCartSimpleBold className='text-xs'/>
//                         }>
//                         </Button> */}
//                       </div>
//                       <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                        

//                         <div className='flex flex-col'>
//                           <p className='text-xs md:text-sm font-semibold'>Product name </p>
//                           <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
//                         </div>

//                         <div className='flex items-center gap-2'>
//                           <div className='h-2 w-2 rounded-full bg-green-400'></div>
//                           <div className='h-2 w-2 rounded-full bg-red-600'></div>
//                         </div>
      
//                         <div className='flex flex-col'>
//                           <div className='flex flex-wrap items-center'>
//                             <p className='text-lg font-semibold'>Rs 5,999.00</p> 
//                             <s className='text-xs font-medium opacity-50 mt-1'>Rs 6,999.00</s>
//                           </div>
//                           <div className='flex items-center gap-2'>
//                             <div className='flex gap-1'>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaRegStarHalfStroke className='text-sm text-yellow-400'/>
//                               <FaRegStar className='text-sm text-yellow-400'/>
//                             </div>
//                             <p className='text-sm opacity-50'>(3)</p>
//                           </div>
//                         </div>
//                       </Link>
//                     </div>

//                     <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
//                       <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
//                         <Link className='h-[150px] relative' to={'/shop/cat/product'}>
//                           <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative z-0'/>
//                         </Link>
//                         {/* <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-25 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
//                             <PiShoppingCartSimpleBold className='text-xs'/>
//                         }>
//                         </Button> */}
//                       </div>
//                       <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                        

//                         <div className='flex flex-col'>
//                           <p className='text-xs md:text-sm font-semibold'>Product name </p>
//                           <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
//                         </div>

//                         <div className='flex items-center gap-2'>
//                           <div className='h-2 w-2 rounded-full bg-green-400'></div>
//                           <div className='h-2 w-2 rounded-full bg-red-600'></div>
//                         </div>
      
//                         <div className='flex flex-col'>
//                           <div className='flex flex-wrap items-center'>
//                             <p className='text-lg font-semibold'>Rs 5,999.00</p> 
//                             <s className='text-xs font-medium opacity-50 mt-1'>Rs 6,999.00</s>
//                           </div>
//                           <div className='flex items-center gap-2'>
//                             <div className='flex gap-1'>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaRegStarHalfStroke className='text-sm text-yellow-400'/>
//                               <FaRegStar className='text-sm text-yellow-400'/>
//                             </div>
//                             <p className='text-sm opacity-50'>(3)</p>
//                           </div>
//                         </div>
//                       </Link>
//                     </div>

//                     <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
//                       <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
//                         <Link className='h-[150px] relative' to={'/shop/cat/product'}>
//                           <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative z-0'/>
//                         </Link>
//                         {/* <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-25 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
//                             <PiShoppingCartSimpleBold className='text-xs'/>
//                         }>
//                         </Button> */}
//                       </div>
//                       <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                        

//                         <div className='flex flex-col'>
//                           <p className='text-xs md:text-sm font-semibold'>Product name </p>
//                           <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
//                         </div>

//                         <div className='flex items-center gap-2'>
//                           <div className='h-2 w-2 rounded-full bg-green-400'></div>
//                           <div className='h-2 w-2 rounded-full bg-red-600'></div>
//                         </div>
      
//                         <div className='flex flex-col'>
//                           <div className='flex flex-wrap items-center'>
//                             <p className='text-lg font-semibold'>Rs 5,999.00</p> 
//                             <s className='text-xs font-medium opacity-50 mt-1'>Rs 6,999.00</s>
//                           </div>
//                           <div className='flex items-center gap-2'>
//                             <div className='flex gap-1'>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaRegStarHalfStroke className='text-sm text-yellow-400'/>
//                               <FaRegStar className='text-sm text-yellow-400'/>
//                             </div>
//                             <p className='text-sm opacity-50'>(3)</p>
//                           </div>
//                         </div>
//                       </Link>
//                     </div>

//                     <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
//                       <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
//                         <Link className='h-[150px] relative' to={'/shop/cat/product'}>
//                           <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative z-0'/>
//                         </Link>
//                         {/* <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-25 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
//                             <PiShoppingCartSimpleBold className='text-xs'/>
//                         }>
//                         </Button> */}
//                       </div>
//                       <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                        

//                         <div className='flex flex-col'>
//                           <p className='text-xs md:text-sm font-semibold'>Product name </p>
//                           <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
//                         </div>

//                         <div className='flex items-center gap-2'>
//                           <div className='h-2 w-2 rounded-full bg-green-400'></div>
//                           <div className='h-2 w-2 rounded-full bg-red-600'></div>
//                         </div>
      
//                         <div className='flex flex-col'>
//                           <div className='flex flex-wrap items-center'>
//                             <p className='text-lg font-semibold'>Rs 5,999.00</p> 
//                             <s className='text-xs font-medium opacity-50 mt-1'>Rs 6,999.00</s>
//                           </div>
//                           <div className='flex items-center gap-2'>
//                             <div className='flex gap-1'>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaRegStarHalfStroke className='text-sm text-yellow-400'/>
//                               <FaRegStar className='text-sm text-yellow-400'/>
//                             </div>
//                             <p className='text-sm opacity-50'>(3)</p>
//                           </div>
//                         </div>
//                       </Link>
//                     </div>

//                     <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
//                       <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
//                         <Link className='h-[150px] relative' to={'/shop/cat/product'}>
//                           <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative z-0'/>
//                         </Link>
//                         {/* <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-25 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
//                             <PiShoppingCartSimpleBold className='text-xs'/>
//                         }>
//                         </Button> */}
//                       </div>
//                       <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                        

//                         <div className='flex flex-col'>
//                           <p className='text-xs md:text-sm font-semibold'>Product name </p>
//                           <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
//                         </div>

//                         <div className='flex items-center gap-2'>
//                           <div className='h-2 w-2 rounded-full bg-green-400'></div>
//                           <div className='h-2 w-2 rounded-full bg-red-600'></div>
//                         </div>
      
//                         <div className='flex flex-col'>
//                           <div className='flex flex-wrap items-center'>
//                             <p className='text-lg font-semibold'>Rs 5,999.00</p> 
//                             <s className='text-xs font-medium opacity-50 mt-1'>Rs 6,999.00</s>
//                           </div>
//                           <div className='flex items-center gap-2'>
//                             <div className='flex gap-1'>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaRegStarHalfStroke className='text-sm text-yellow-400'/>
//                               <FaRegStar className='text-sm text-yellow-400'/>
//                             </div>
//                             <p className='text-sm opacity-50'>(3)</p>
//                           </div>
//                         </div>
//                       </Link>
//                     </div>

//                     <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
//                       <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
//                         <Link className='h-[150px] relative' to={'/shop/cat/product'}>
//                           <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative z-0'/>
//                         </Link>
//                         {/* <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-25 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
//                             <PiShoppingCartSimpleBold className='text-xs'/>
//                         }>
//                         </Button> */}
//                       </div>
//                       <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                        

//                         <div className='flex flex-col'>
//                           <p className='text-xs md:text-sm font-semibold'>Product name </p>
//                           <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
//                         </div>

//                         <div className='flex items-center gap-2'>
//                           <div className='h-2 w-2 rounded-full bg-green-400'></div>
//                           <div className='h-2 w-2 rounded-full bg-red-600'></div>
//                         </div>
      
//                         <div className='flex flex-col'>
//                           <div className='flex flex-wrap items-center'>
//                             <p className='text-lg font-semibold'>Rs 5,999.00</p> 
//                             <s className='text-xs font-medium opacity-50 mt-1'>Rs 6,999.00</s>
//                           </div>
//                           <div className='flex items-center gap-2'>
//                             <div className='flex gap-1'>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaRegStarHalfStroke className='text-sm text-yellow-400'/>
//                               <FaRegStar className='text-sm text-yellow-400'/>
//                             </div>
//                             <p className='text-sm opacity-50'>(3)</p>
//                           </div>
//                         </div>
//                       </Link>
//                     </div>

//                     <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
//                       <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
//                         <Link className='h-[150px] relative' to={'/shop/cat/product'}>
//                           <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative z-0'/>
//                         </Link>
//                         {/* <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-25 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
//                             <PiShoppingCartSimpleBold className='text-xs'/>
//                         }>
//                         </Button> */}
//                       </div>
//                       <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                        

//                         <div className='flex flex-col'>
//                           <p className='text-xs md:text-sm font-semibold'>Product name </p>
//                           <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
//                         </div>

//                         <div className='flex items-center gap-2'>
//                           <div className='h-2 w-2 rounded-full bg-green-400'></div>
//                           <div className='h-2 w-2 rounded-full bg-red-600'></div>
//                         </div>
      
//                         <div className='flex flex-col'>
//                           <div className='flex flex-wrap items-center'>
//                             <p className='text-lg font-semibold'>Rs 5,999.00</p> 
//                             <s className='text-xs font-medium opacity-50 mt-1'>Rs 6,999.00</s>
//                           </div>
//                           <div className='flex items-center gap-2'>
//                             <div className='flex gap-1'>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaRegStarHalfStroke className='text-sm text-yellow-400'/>
//                               <FaRegStar className='text-sm text-yellow-400'/>
//                             </div>
//                             <p className='text-sm opacity-50'>(3)</p>
//                           </div>
//                         </div>
//                       </Link>
//                     </div>

//                     <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
//                       <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
//                         <Link className='h-[150px] relative' to={'/shop/cat/product'}>
//                           <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative z-0'/>
//                         </Link>
//                         {/* <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-25 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
//                             <PiShoppingCartSimpleBold className='text-xs'/>
//                         }>
//                         </Button> */}
//                       </div>
//                       <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                        

//                         <div className='flex flex-col'>
//                           <p className='text-xs md:text-sm font-semibold'>Product name </p>
//                           <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
//                         </div>

//                         <div className='flex items-center gap-2'>
//                           <div className='h-2 w-2 rounded-full bg-green-400'></div>
//                           <div className='h-2 w-2 rounded-full bg-red-600'></div>
//                         </div>
      
//                         <div className='flex flex-col'>
//                           <div className='flex flex-wrap items-center'>
//                             <p className='text-lg font-semibold'>Rs 5,999.00</p> 
//                             <s className='text-xs font-medium opacity-50 mt-1'>Rs 6,999.00</s>
//                           </div>
//                           <div className='flex items-center gap-2'>
//                             <div className='flex gap-1'>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaStar className='text-sm text-yellow-400'/>
//                               <FaRegStarHalfStroke className='text-sm text-yellow-400'/>
//                               <FaRegStar className='text-sm text-yellow-400'/>
//                             </div>
//                             <p className='text-sm opacity-50'>(3)</p>
//                           </div>
//                         </div>
//                       </Link>
//                     </div>
                    
//                 </div>
//                 <div className='flex items-center md:col-span-2 lg:col-span-3 justify-center py-4'>
//                   <Pagination size='sm' showControls total={10} initialPage={1} className='flex w-full md:hidden' />
//                   <Pagination size='md' showControls total={10} initialPage={1} className='w-full hidden md:flex' />
//                 </div>
//               </div>
//             </div>