import React, { useEffect, useState } from 'react'
import Container from '../componets/Container'
import {Button, Card, CardBody, CardFooter, Image, Skeleton} from "@nextui-org/react";
import { MdArrowRightAlt } from "react-icons/md";
import { FaRegStarHalfStroke, FaRegStar , FaStar} from "react-icons/fa6";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PiShoppingCartSimpleBold } from 'react-icons/pi';
import Marquee from "react-fast-marquee";
import { listProducts } from '../actions/productActions';
import { useDispatch, useSelector } from 'react-redux';

const HomeScreen = () => {
  const dispatch = useDispatch();

  // Accessing productList from Redux and setting a default empty object for products to avoid errors
  const productList = useSelector(state => state.productList);
  const { loading, products } = productList;

  // Local state to store grouped products by category
  const [groupedProducts, setGroupedProducts] = useState({});

  useEffect(() => {
    // Dispatch action to load products
    dispatch(listProducts(1, 'all'));
  }, [dispatch]);

  useEffect(() => {
    // Check if products exist and group them by category
    if (products && products.products) {
      const groupProductsByCategory = (productsArray) => {
        if (!Array.isArray(productsArray)) return {};
        return productsArray.reduce((acc, product) => {
          const categoryName = product?.category?.name || 'Uncategorized';
          const categorySlug = product?.category?.slug || 'uncategorized';
  
          // Initialize the category if it doesn't exist in the accumulator
          if (!acc[categorySlug]) {
            acc[categorySlug] = {
              name: categoryName,
              slug: categorySlug,
              products: []
            };
          }
  
          // Add the product to the category's product list
          acc[categorySlug].products.push(product);
  
          return acc;
        }, {});
      };
  
      setGroupedProducts(groupProductsByCategory(products.products));
    }
  }, [products]);
  



  return (
    <div className=' flex flex-col gap-12'>
      <section className='h-fit w-full'>
        <div className='h-fit w-full max-w-[1100px] mx-auto px-6 grid grid-cols-1 md:grid-cols-2 gap-2 items-center'>
          <div className='flex flex-col gap-4 py-12 '>
            <p className='text-4xl font-black'>Elevate Your Style with Our Exquisite Collection
            ,</p>
            <p className='text-sm opacity-50'>Welcome to our world of curated luxury. From timeless classics to cutting-edge designs, we present a symphony of craftsmanship and sophistication. Explore our meticulously crafted products that redefine elegance.</p>
            <Button className='w-fit bg-[#54C1C4] text-black ' endContent={
              <MdArrowRightAlt/>
            }>
              Shop Now
            </Button>
          </div>
          <div className='h-[400px] w-full bg-gray-100 rounded-bl-[75px] '>

          </div>
        </div>
      </section>

      {/* <section className='h-fit w-full'>
        <div className='h-fit w-full items-center'>
          <div className='w-full flex flex-col gap-4 py-12 '>
            <Marquee className='flex items-center'>
              <div className='h-20 flex items-center'>
                <img src='https://static.vecteezy.com/system/resources/previews/014/018/566/non_2x/samsung-logo-on-transparent-background-free-vector.jpg' alt='' className='h-20 my-auto object-cover mx-8 opacity-25' />
              </div>
              <div className='h-20 flex items-center'>
                <img src='https://pngimg.com/uploads/apple_logo/apple_logo_PNG19688.png' alt='' className='h-20 my-auto object-cover mx-8 opacity-25' />
              </div>
              <div className='h-20 flex items-center'>
                <img src='https://pngimg.com/uploads/apple_logo/apple_logo_PNG19688.png' alt='' className='h-20 my-auto object-cover mx-8 opacity-25' />
              </div>
            </Marquee>
            
          </div>
        </div>
      </section> */}

      <section className='h-fit w-full'>
        <div className='h-fit w-full max-w-[1100px] mx-auto px-6 flex flex-col gap-8'>
            <div className='flex items-center justify-between'>
              <p className='text-4xl font-black'>Tranding</p>
              <Button className="text-base bg-[#54C1C4] text-black pointer-events-none flex-shrink-0" isIconOnly startContent={
                  <MdArrowRightAlt className='text-2xl'/>
                }>
              </Button>
            </div>
            {
              loading?
              <div className='grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 '>
                <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
                  <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
                    <Skeleton className='rounded-[4px]'>
                      <Link className='h-[150px]' to={'/shop/cat/product'}>
                        <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative '/>
                      </Link>
                    </Skeleton>
                    <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-10 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
                        <PiShoppingCartSimpleBold className='text-xs'/>
                    }>
                    </Button>
                  </div>
                  <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                    

                    <div className='flex flex-col gap-1'>
                      <Skeleton className='rounded-[6px]'>
                        <p className='text-sm font-semibold'>Product name </p>
                      </Skeleton>
                      <Skeleton className='rounded-[6px]'>
                        <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
                      </Skeleton>
                    </div>

  
                    <div className='flex flex-col'>
                      <div className='flex flex-wrap items-center'>
                        <Skeleton className='rounded-[6px]'>
                          <p className='text-lg font-semibold'>Rs 5,999.00</p> 
                        </Skeleton>
                        {/* <s className='text-xs font-medium opacity-50 mt-1'>Rs 6,999.00</s> */}
                      </div>
                      <div className='flex items-center gap-2'>
                        <div className='flex gap-1'>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                        </div>
                        <Skeleton className='rounded-[6px] opacity-0'>
                          <p className='text-sm opacity-50'>(3)</p>
                        </Skeleton>
                      </div>
                    </div>
                  </Link>
                </div>   
                <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
                  <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
                    <Skeleton className='rounded-[4px]'>
                      <Link className='h-[150px]' to={'/shop/cat/product'}>
                        <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative '/>
                      </Link>
                    </Skeleton>
                    <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-10 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
                        <PiShoppingCartSimpleBold className='text-xs'/>
                    }>
                    </Button>
                  </div>
                  <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                    

                    <div className='flex flex-col gap-1'>
                      <Skeleton className='rounded-[6px]'>
                        <p className='text-sm font-semibold'>Product name </p>
                      </Skeleton>
                      <Skeleton className='rounded-[6px]'>
                        <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
                      </Skeleton>
                    </div>

  
                    <div className='flex flex-col'>
                      <div className='flex flex-wrap items-center'>
                        <Skeleton className='rounded-[6px]'>
                          <p className='text-lg font-semibold'>Rs 5,999.00</p> 
                        </Skeleton>
                        {/* <s className='text-xs font-medium opacity-50 mt-1'>Rs 6,999.00</s> */}
                      </div>
                      <div className='flex items-center gap-2'>
                        <div className='flex gap-1'>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                        </div>
                        <Skeleton className='rounded-[6px] opacity-0'>
                          <p className='text-sm opacity-50'>(3)</p>
                        </Skeleton>
                      </div>
                    </div>
                  </Link>
                </div>   
                <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
                  <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
                    <Skeleton className='rounded-[4px]'>
                      <Link className='h-[150px]' to={'/shop/cat/product'}>
                        <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative '/>
                      </Link>
                    </Skeleton>
                    <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-10 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
                        <PiShoppingCartSimpleBold className='text-xs'/>
                    }>
                    </Button>
                  </div>
                  <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                    

                    <div className='flex flex-col gap-1'>
                      <Skeleton className='rounded-[6px]'>
                        <p className='text-sm font-semibold'>Product name </p>
                      </Skeleton>
                      <Skeleton className='rounded-[6px]'>
                        <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
                      </Skeleton>
                    </div>

  
                    <div className='flex flex-col'>
                      <div className='flex flex-wrap items-center'>
                        <Skeleton className='rounded-[6px]'>
                          <p className='text-lg font-semibold'>Rs 5,999.00</p> 
                        </Skeleton>
                        {/* <s className='text-xs font-medium opacity-50 mt-1'>Rs 6,999.00</s> */}
                      </div>
                      <div className='flex items-center gap-2'>
                        <div className='flex gap-1'>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                        </div>
                        <Skeleton className='rounded-[6px] opacity-0'>
                          <p className='text-sm opacity-50'>(3)</p>
                        </Skeleton>
                      </div>
                    </div>
                  </Link>
                </div>           
                <div  className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
                  <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
                    <Skeleton className='rounded-[4px]'>
                      <Link className='h-[150px]' to={'/shop/cat/product'}>
                        <img src='https://static-01.daraz.lk/p/4dbbe6982c89675213a27ca6f2968d77.jpg_400x400q75.jpg_.webp' alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative '/>
                      </Link>
                    </Skeleton>
                    <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-10 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
                        <PiShoppingCartSimpleBold className='text-xs'/>
                    }>
                    </Button>
                  </div>
                  <Link to={'/shop/cat/product'} className='flex flex-col pt-3 gap-2'>
                    

                    <div className='flex flex-col gap-1'>
                      <Skeleton className='rounded-[6px]'>
                        <p className='text-sm font-semibold'>Product name </p>
                      </Skeleton>
                      <Skeleton className='rounded-[6px]'>
                        <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
                      </Skeleton>
                    </div>

  
                    <div className='flex flex-col'>
                      <div className='flex flex-wrap items-center'>
                        <Skeleton className='rounded-[6px]'>
                          <p className='text-lg font-semibold'>Rs 5,999.00</p> 
                        </Skeleton>
                        {/* <s className='text-xs font-medium opacity-50 mt-1'>Rs 6,999.00</s> */}
                      </div>
                      <div className='flex items-center gap-2'>
                        <div className='flex gap-1'>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                          <Skeleton className='rounded-[6px]'>
                            <FaStar className='text-sm text-yellow-400'/>
                          </Skeleton>
                        </div>
                        <Skeleton className='rounded-[6px] opacity-0'>
                          <p className='text-sm opacity-50'>(3)</p>
                        </Skeleton>
                      </div>
                    </div>
                  </Link>
                </div>   
              </div>:
              products && products.products  ?
              <div className='grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 '>
                  {
                    products.products.filter(i => i.top_products == true).slice(0 , 4).map(i=>(
                      <div key={i._id} className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
                        <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
                          <Link to={`/shop/category/${i.slug}`} className='h-[150px]'>
                            <img src={
                              i.images[0].image 
                            } alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative '/>
                          </Link>
                          <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-10 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
                              <PiShoppingCartSimpleBold className='text-xs'/>
                          }>
                          </Button>
                        </div>
                        <Link to={`/shop/category/${i.slug}`} className='flex flex-col pt-3 gap-2'>
                          

                          <div className='flex flex-col gap-1'>
                            <p className='text-sm font-semibold'>{i.name}</p>
                            <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
                          </div>

                
        
                          <div className='flex flex-col'>
                            <div className='flex flex-wrap items-center'>
                              <p className='text-lg font-semibold'>Rs {i.discount_price}</p> 
                              <s className='text-xs font-medium opacity-50 mt-1'>Rs {i.price}</s>
                            </div>
                            <div className='flex items-center gap-2'>
                            <div className='flex gap-1'>
                              {[...Array(5)].map((_, index) => (
                                <FaStar
                                  key={index}
                                  className={`text-sm ${index < i.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                />
                              ))}
                            </div>
                            <p className='text-sm opacity-50'>({i.numReviews})</p>
                          </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  }           
              </div>:
              ''
            }
        </div>
      </section>

      <section className='h-fit w-full'>
        <div className='h-fit w-full max-w-[1100px] mx-auto px-6 flex flex-col gap-8'>
          {loading ? (
            <div>Loading...</div>
          ) : (
            Object.keys(groupedProducts).length > 0 ? (
              Object.entries(groupedProducts).map(([categorySlug, categoryData]) => (
                <div key={categoryData} className='flex flex-col gap-8'>
                  <div className='flex items-center justify-between'>
                    <p className='text-4xl font-black'>{categoryData.name}</p>
                    <Link to={`/shop/${categorySlug}`}>
                      <Button className="text-base bg-[#54C1C4] text-black pointer-events-none flex-shrink-0" isIconOnly startContent={
                          <MdArrowRightAlt className='text-2xl'/>
                        }>
                      </Button>
                    </Link>
                  </div>

                  <div className='grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4'>
                    {groupedProducts[categorySlug]?.products?.map((i) => (
                      <div key={i._id} className='h-fit w-full flex flex-col rounded-[16px] bg-gray-50 p-2'>
                        <div className='h-[150px] w-full rounded-[8px] overflow-hidden bg-white relative'>
                          <Link to={`/shop/category/${i.slug}`} className='h-[150px]'>
                            <img src={
                              i.images[0].image 
                            } alt='' className='h-full w-full object-contain rounded-[4px] hover:scale-105 duration-300 relative '/>
                          </Link>
                          <Button size='sm' className="text-base border-[2px] border-[#54C1C4] border-opacity-10 hover:border-opacity-100 bg-transparent focus:bg-[#54C1C4] hover:bg-[#54C1C4] absolute z-20 top-2 right-2 text-[#54C1C4] focus:text-black hover:text-black" isIconOnly startContent={
                              <PiShoppingCartSimpleBold className='text-xs'/>
                          }>
                          </Button>
                        </div>
                        <Link to={`/shop/category/${i.slug}`} className='flex flex-col pt-3 gap-2'>
                          

                          <div className='flex flex-col gap-1'>
                            <p className='text-sm font-semibold'>{i.name}</p>
                            <p className='text-xs font-medium opacity-50'>/10kg /20kg</p>  
                          </div>

                
        
                          <div className='flex flex-col'>
                            <div className='flex flex-wrap items-center'>
                              <p className='text-lg font-semibold'>Rs {i.discount_price}</p> 
                              <s className='text-xs font-medium opacity-50 mt-1'>Rs {i.price}</s>
                            </div>
                            <div className='flex items-center gap-2'>
                            <div className='flex gap-1'>
                              {[...Array(5)].map((_, index) => (
                                <FaStar
                                  key={index}
                                  className={`text-sm ${index < i.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                />
                              ))}
                            </div>
                            <p className='text-sm opacity-50'>({i.numReviews})</p>
                          </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div>No products available</div>
            )
          )}
        </div>
      </section>



      {/* <Container className="">
        {list.map((item, index) => (
            <Card shadow="none" key={index} radius="sm" isPressable onPress={() => console.log("item pressed")}>
              <CardBody className="overflow-visible p-0">
                <Image
                  shadow="none"
                  radius="sm"
                  width="100%"
                  alt={item.title}
                  className="w-full object-cover h-[240px]"
                  src={"https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cHJvZHVjdHxlbnwwfHwwfHx8MA%3D%3D"}
                />
              </CardBody>
              <CardFooter className="text-small justify-between">
                <b>{item.title}</b>
                <p className="text-default-500">{item.price}</p>
              </CardFooter>
            </Card>
          ))}
      </Container> */}
    </div>
  )
}

export default HomeScreen