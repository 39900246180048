import { BreadcrumbItem, Breadcrumbs, Button, Tab, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tabs } from '@nextui-org/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

const ProfileScreen = () => {
  
  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin


  return (
    <div className='h-fit w-full flex flex-col gap-8'>
      <section className='h-fit w-full'>
        <div className='h-fit w-full max-w-[1100px] mx-auto px-6'>
          <Breadcrumbs className='py-4'>
            <BreadcrumbItem className='font-medium'>Home</BreadcrumbItem>
            <BreadcrumbItem className='font-medium'>My Account</BreadcrumbItem>
          </Breadcrumbs> 
          <div className='h-fit w-full gap-8 items-start'>
            <div className='w-full h-[400px]'>
              <Table aria-label="Example table with dynamic content" className='gap-2 flex flex-col'>
                <TableHeader >
                  <TableColumn className='font-bold opacity-50 text-base' >Order</TableColumn>
                  <TableColumn className='font-bold opacity-50 text-base' >Date</TableColumn>
                  <TableColumn className='font-bold opacity-50 text-base' >Payment</TableColumn>
                  <TableColumn className='font-bold opacity-50 text-base' >Total</TableColumn>
                  <TableColumn className='font-bold opacity-50 text-base' >Delivery</TableColumn>
                  <TableColumn className='font-bold opacity-50 text-base' >Items</TableColumn>
                </TableHeader>
                <TableBody >
                  
                    {
                      userInfo && userInfo.orders ?
                      userInfo.orders.map(i=>(
                        <TableRow key={i._id} className=''>
                          <TableCell className='text-xs font-bold'>
                            <Link to={`/profile/order/${i._id}`} >
                              #{i._id}
                            </Link>
                          </TableCell>
                          <TableCell className='text-xs opacity-75'>
                            <Link to={`/profile/order/${i._id}`} >
                              {format(new Date(i.createdAt), 'dd MMMM yyyy')}
                            </Link>
                          </TableCell>
                          <TableCell className='text-xs opacity-75'>{i.paymentMethod === 'COD'?
                            <Button variant='bordered' size='sm' color="danger" endContent={
                              <div className='h-[4px] w-[4px] rounded-full bg-red-600'></div>
                            }>
                              Panding
                            </Button>    
                          :
                            <Button variant='bordered' size='sm' color="success" endContent={
                              <div className='h-[4px] w-[4px] rounded-full bg-green-600'></div>
                            }>
                              Success
                            </Button>
                          }</TableCell>
                          <TableCell className='text-xs opacity-75'>
                            <Link to={`/profile/order/${i._id}`} >
                              {i.totalPrice}
                            </Link>
                          </TableCell>
                          <TableCell className='text-xs opacity-75'>
                            <Link to={`/profile/order/${i._id}`} >
                              {i.isDelivered? 'Delivered' :'On the way'}
                            </Link>
                          </TableCell>
                          <TableCell className='text-xs opacity-75'>
                            <Link to={`/profile/order/${i._id}`} >
                              {i.orderItems.length} items
                            </Link>
                          </TableCell>

                        </TableRow>
                      )): 
                      <TableRow>
                        <TableCell>gi</TableCell>
                        <TableCell>gi</TableCell>
                        <TableCell>gi</TableCell>
                        <TableCell>gi</TableCell>
                        <TableCell>gi</TableCell>
                        <TableCell>gi</TableCell>
                      </TableRow>
                    }

                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ProfileScreen